export const cityArrivalMixin = {
    mounted() {
        this.getFaqAll();
    },
    data() {
        return {
            loading: false,
            form: {
                fields: {
                    parent_id: null,
                    title: null,
                    subtitle: null,
                    preview_title: null,
                    attractions_title: null,
                    search_title: null,
                    slug: null,
                    image: null,
                    image_preview: null,
                    images_delete: null,
                    images_preview_delete: null,
                    description: null,
                    published: false,
                    seo_title: null,
                    seo_text: null,
                    sale_visible: false,
                    order: 0,
                    faq_ids: null,
                },
                hidden_tag: null,
                attractions: [],
                seo: this.getDefaultSeo(),
            },
            faq: [],
            attractionOptions: [],
            parentOptions: [],
            seoHeaders: [
                { field: 'code', label: 'Название параметра' },
                { field: 'value', label: 'Значение' },
            ],
        };
    },
    methods: {
        getDefaultSeo() {
            return [
                { code: 'title', value: '' },
                { code: 'keywords', value: '' },
                { code: 'description', value: '' },
                { code: 'query', value: '' },
            ];
        },

        async getFaqAll() {
            this.loading = true;

            try {
                const { data } = await this.axios.get(`/faq-all`);
                this.faq = data.data;
            } catch (ex) {
                console.log('cannot fetch point edit params ' + ex);
            } finally {
                this.loading = false;
            }
        },
    },
};
